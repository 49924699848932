import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {

    if(prestashop.responsive.mobile) {
    initReadMore();
}

    prestashop.on('responsive update', (e) => {

        if(e.mobile){
            initReadMore();
        }
    });
});

function initReadMore(){
    if (prestashop.page.page_name === "index") {
        var _pdtdesc = $('#block-text');
        if (_pdtdesc.length > 0) {
            $('#block-text').readmore({
                speed: 75,
                collapsedHeight: 200,
                lessLink: '<a class="col-lg-8 offset-lg-2" href="#">Fermer</a>',
                moreLink: '<a class="col-lg-8 offset-lg-2" href="#">Voir la suite</a>'
            });
        }
    }
}