import $ from 'jquery';

$(document).on('show.bs.modal','#js-modal-ajaxcontent', function (event) {

    var buttonModal = $(event.relatedTarget),
        titleModal = buttonModal.data('title'),
        ajaxurl = typeof buttonModal.data('url') !=="undefined" ? buttonModal.data('url') : buttonModal.attr('href'),
        ajaxUrlModal = ajaxurl +'?content_only=1',
        modal = $(this),
        contentModal = 'Error...';
    resetModalSimpleContent(modal);

    // console.log($(this));
    // console.log(ajaxUrlModal);
    if(typeof titleModal !== "undefined"){
        $('.js-modal-body__title',modal).text(titleModal);
    }

    if(typeof ajaxUrlModal !== "undefined") {

        $.get(ajaxUrlModal)
            .done(function (data) {
                contentModal = $(data).find('.page-content--cms').contents();
            })
            .always(function () {
                $('.js-modal-body__spinner', modal).hide();
                $('.js-modal-body__content', modal).html(contentModal);
            });
    }
});

function resetModalSimpleContent(modal){
    $('.js-modal-body__spinner:hidden',modal).show();
    $('.js-modal-body__content',modal).html('');
    $('.js-modal-body__title',modal).text('');

}