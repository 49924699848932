import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {

    initReadMore();

});

function initReadMore(){
    if (prestashop.page.page_name === "category") {
        var _pdtdesc = $('#block-text');
        if (_pdtdesc.length > 0) {
            $('#block-text').readmore({
                speed: 75,
                embedCSS: false,
                lessLink: '<a class="c-blockcat-link" href="#">Fermer</a>',
                moreLink: '<a class="c-blockcat-link" href="#">En savoir plus</a>',
            });
        }
    }
}