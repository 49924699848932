import $ from 'jquery';

$(document).on('show.bs.modal','#js-modal-video', function (event) {

    var buttonModal = $(event.relatedTarget),
        titleModal = buttonModal.data('title'),
        videoyt = buttonModal.data('video'),
        _iframe = '<iframe class="embed-responsive-item" name="video" src="'+ videoyt +'" frameborder="0" width="560" height="315" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

    $('#js-modal-video-title').html();
    $('#js-video-iframe-wrapper').html();

    if(typeof titleModal !== "undefined"){
        $('#js-modal-video-title').text(titleModal);
    }

    $('#js-video-iframe-wrapper').html(_iframe);

});

$(document).on('hidden.bs.modal','#js-modal-video', function (event) {
    $('#js-modal-video-title').html('');
    $('#js-video-iframe-wrapper').html('');
    console.log('hidden');
});


$(document).on('click','.js-rckt-show-video' ,function(e){
    var videoyt = $(this).data('video'),
        _target = $(this).data('target'),
        _iframe = '<iframe class="embed-responsive-item" name="video" src="'+ videoyt +'" frameborder="0" width="560" height="315" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    $(_target).html(_iframe);
});

$(document).on('lazybeforeunveil','.js-connection-fast .js-videoplayer' ,function(e){
    var videoyt = $(this).data('video'),
    _iframe = '<iframe class="embed-responsive-item" name="video" src="'+ videoyt +'" frameborder="0" width="560" height="315" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
    $(this).html(_iframe);
});
