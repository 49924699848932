// js-pdt-images-zoom
// $(document).ready(function () {
//
//     galleryInit();
//     prestashop.on('updatedProduct', function (event) {
//         galleryInit();
//     });
//
// });

$(document).on('click','#js-pdtcover img',function(e){
    var _parent = $(this).closest('[data-imgindex]');
    var _index = 0;
    if(_parent.length > 0){
        _index = _parent.data('imgindex');
    }
    galleryInit(_index);

});

function buildItems()
{
    var items =[];
    let images = $('#js-pdt-images-zoom').data('images');
    $.each(images,function (index,el) {

        var img = {
            src: el.bySize.large_default.url,
            msrc : el.bySize.pdt_540.url,
            w: el.bySize.large_default.width,
            h: el.bySize.large_default.height
        };
        items.push(img);
    });


    return items;
}

function galleryInit(index)
{
    var pswpElement = document.querySelectorAll('.pswp')[0];
    buildItems();
// build items array
    var items = buildItems();
    var options = {
        // optionName: 'option value'
        // for example:
        index: index // start at first slide
    };
    var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
}