import throttle from 'lodash.throttle';
let scrollPosition = Math.round(window.pageYOffset || document.documentElement.scrollTop);
let lastScrollPosition = scrollPosition;
let scrollDirection = false;
let lastScrollDirection = false;

var getScrollDir = throttle(() => {
    // console.log(scrollPosition,'scrollPosition');
    // console.log(lastScrollPosition,'lastScrollPosition');
    // console.log(scrollDirection,'scrollDirection');
    scrollPosition = Math.round(window.pageYOffset || document.documentElement.scrollTop);


if (scrollPosition > lastScrollPosition) {
    scrollDirection = 'down';
}else if(scrollPosition < lastScrollPosition){
    scrollDirection = 'up';
}
lastScrollPosition = scrollPosition;

if(scrollDirection && lastScrollDirection !== scrollDirection){
    removeScrollClass();
    $('body').addClass('is-scroll-'+scrollDirection);
    lastScrollDirection = scrollDirection;
}

}, 300);

window.addEventListener('scroll', getScrollDir);

function removeScrollClass() {
    $('body').removeClass('is-scroll-up is-scroll-down');

}